import Hammer from 'hammerjs'
import { Particles } from './particles'

let lastActivePageName = undefined
let activePageName = undefined
let activePageClass = undefined
let activeAnimation = undefined
let animating = false

activePageName = pathNameToPageName()
activePageClass = 'page-' + activePageName
document.title = pageNameToTitle(activePageName)
history.replaceState(null, document.title, pageNameToPath(activePageName))

let lastWheelEvent

document.addEventListener(
    'wheel',
    (e) => {
        e.preventDefault()

        // prevent touchpad event repeat
        if (
            lastWheelEvent === undefined ||
            Math.sign(e.deltaY) !== Math.sign(lastWheelEvent.deltaY) ||
            Math.sign(e.deltaX) !== Math.sign(lastWheelEvent.deltaX)
        ) {
            if (activePageName === 'home') {
                if (Math.sign(e.deltaY) === 1) {
                    changePage('work')
                } else if (Math.sign(e.deltaX) === 1) {
                    changePage('about')
                }
            } else if (activePageName === 'about') {
                if (Math.sign(e.deltaX) === -1) {
                    changePage('home')
                }
            } else if (activePageName === 'work') {
                if (Math.sign(e.deltaY) === -1) {
                    changePage('home')
                }
            }
        }

        lastWheelEvent = e
    },
    { passive: false }
)

document.body.addEventListener('animationend', endPageAnimation)

const mapping = {
    'about.html': 'about',
    'work.html': 'work',
}

const pageButtons = document.getElementsByClassName('button-for-page')
for (const b of pageButtons) {
    b.addEventListener('click', (e) => {
        e.preventDefault()
        changePage(mapping[b.getAttribute('href').replace('/', '')] || 'home')
    })
}

window.addEventListener('popstate', () => {
    changePage(pathNameToPageName(), false)
})

function changePage(page, addToHist = true) {
    if (activePageName === page) return

    lastActivePageName = activePageName
    activePageName = page

    if (animating) {
        changePageAnimation()
    } else {
        startPageAnimation()
    }

    document.title = pageNameToTitle(activePageName)

    if (addToHist) {
        history.pushState(null, document.title, pageNameToPath(activePageName))
    }
}

function startPageAnimation() {
    animating = true
    activeAnimation = 'anim-from-' + lastActivePageName + '-to-' + activePageName

    document.body.classList.add(activeAnimation)
}

function changePageAnimation() {
    const lastActiveAnimation = activeAnimation
    activeAnimation = 'anim-from-' + lastActivePageName + '-to-' + activePageName
    document.body.classList.replace(lastActiveAnimation, activeAnimation)

    const lastActivePageClass = activePageClass
    activePageClass = 'page-' + lastActivePageName
    document.body.classList.replace(lastActivePageClass, activePageClass)
}

function endPageAnimation() {
    if (!animating) return
    animating = false

    const lastActivePageClass = activePageClass
    activePageClass = 'page-' + activePageName
    document.body.classList.replace(lastActivePageClass, activePageClass)

    document.body.classList.remove(activeAnimation)
}

function pathNameToPageName() {
    switch (window.location.pathname) {
        case '/about':
            return 'about'
        case '/work':
            return 'work'
        default:
            return 'home'
    }
}

function pageNameToPath(name) {
    switch (name) {
        case 'about':
            return '/about'
        case 'work':
            return '/work'
        default:
            return '/'
    }
}

function pageNameToTitle(name) {
    const defaultTitle = 'Teodor Tanasoaia'
    switch (name) {
        case 'about':
            return 'About - ' + defaultTitle
        case 'work':
            return 'Work - ' + defaultTitle
        default:
            return defaultTitle
    }
}

const hammer = new Hammer.Manager(document.body)
hammer.add(new Hammer.Swipe())

hammer.on('swipeleft', () => {
    if (activePageName === 'home') changePage('about')
})

hammer.on('swiperight', () => {
    if (activePageName === 'about') changePage('home')
})

hammer.on('swipeup', () => {
    if (activePageName === 'home') changePage('work')
})

hammer.on('swipedown', () => {
    if (activePageName === 'work') changePage('home')
})

const _ = new Particles({
    selector: '#particles',
    speed: 0.2,
    // color: '#FAFAFA',
    // sizeVariations: 2,
    maxParticles: 16 * 4,
    responsive: [
        {
            breakpoint: 992,
            options: {
                maxParticles: 12 * 4,
            },
        },
        {
            breakpoint: 576,
            options: {
                maxParticles: 8 * 4,
            },
        },
    ],
})
