const frameTime = 1000 / 24 // 24 FPS

export class Particles {
    constructor(settings) {
        this.element = null
        this.context = null
        this.ratio = null
        this.breakpoints = []
        this.activeBreakpoint = null
        this.breakpointSettings = []
        this.originalSettings = null
        this.storage = []

        this.options = {
            responsive: null,
            selector: null,
            maxParticles: 100,
            sizeVariations: 3,
            showParticles: true,
            speed: 0.5,
            color: '#000000',
            minDistance: 120,
            // connectParticles: false,
            ...settings,
        }
        this.originalSettings = JSON.parse(JSON.stringify(this.options))

        this._anim = this._animate.bind(this)

        this._initializeCanvas()
        this._initializeEvents()
        this._registerBreakpoints()
        this._checkResponsive()
        this._initializeStorage()
        this.lastTS = performance.now()
        this._animate(this.lastTS)

        return this
    }

    // destroy() {
    //     this.storage = []
    //     this.element.remove()

    //     window.removeEventListener('resize', this.listener, false)
    //     window.cancelAnimationFrame(this._animation)
    // }

    /**
     * Setup the canvas element.
     *
     * @private
     */
    _initializeCanvas() {
        let devicePixelRatio
        let backingStoreRatio

        if (!this.options.selector) {
            console.warn(
                'particles.js: No selector specified! Check https://github.com/marcbruederlin/particles.js#options'
            )
            return false
        }

        this.element = document.querySelector(this.options.selector)
        this.element.setAttribute(
            'viewBox',
            `0 0 ${this.element.clientWidth} ${this.element.clientHeight}`
        )
        // this.element.setAttribute('height', this.element.clientHeight)
        // this.context = this.element.getContext('2d')

        // devicePixelRatio = window.devicePixelRatio || 1
        // backingStoreRatio =
        //     this.context.webkitBackingStorePixelRatio ||
        //     this.context.mozBackingStorePixelRatio ||
        //     this.context.msBackingStorePixelRatio ||
        //     this.context.oBackingStorePixelRatio ||
        //     this.context.backingStorePixelRatio ||
        //     1

        // this.ratio = devicePixelRatio / backingStoreRatio
        // this.element.width = this.element.offsetParent
        //     ? this.element.offsetParent.clientWidth * this.ratio
        //     : this.element.clientWidth * this.ratio

        // if (this.element.offsetParent && this.element.offsetParent.nodeName === 'BODY') {
        //     this.element.height = window.innerHeight * this.ratio
        // } else {
        //     this.element.height = this.element.offsetParent
        //         ? this.element.offsetParent.clientHeight * this.ratio
        //         : this.element.clientHeight * this.ratio
        // }
        // this.element.style.width = '100%'
        // this.element.style.height = '100%'

        // this.context.scale(this.ratio, this.ratio)
    }

    /**
     * Register event listeners.
     *
     * @private
     */
    _initializeEvents() {
        this.listener = this._resize.bind(this)
        window.addEventListener('resize', this.listener, false)
    }

    /**
     * Initialize the particle storage.
     *
     * @private
     */
    _initializeStorage() {
        for (const particle of this.storage) {
            particle.element.remove()
        }

        this.storage = []

        for (let i = 0; i < this.options.maxParticles; i++) {
            this.storage.push(new Particle(this.element, this.options))
        }
    }

    /**
     * Register responsive breakpoints if the user declared some.
     *
     * @private
     */
    _registerBreakpoints() {
        let breakpoint
        let currentBreakpoint
        let l
        const responsiveSettings = this.options.responsive || null

        if (
            typeof responsiveSettings === 'object' &&
            responsiveSettings !== null &&
            responsiveSettings.length
        ) {
            for (breakpoint in responsiveSettings) {
                l = this.breakpoints.length - 1
                currentBreakpoint = responsiveSettings[breakpoint].breakpoint

                if (responsiveSettings.hasOwnProperty(breakpoint)) {
                    while (l >= 0) {
                        if (this.breakpoints[l] && this.breakpoints[l] === currentBreakpoint) {
                            this.breakpoints.splice(l, 1)
                        }

                        l--
                    }

                    this.breakpoints.push(currentBreakpoint)
                    this.breakpointSettings[currentBreakpoint] =
                        responsiveSettings[breakpoint].options
                }
            }

            this.breakpoints.sort((a, b) => b - a)
        }
    }

    /**
     * Check if a breakpoint is active and load the breakpoints options.
     *
     * @private
     */
    _checkResponsive() {
        let breakpoint
        let targetBreakpoint = false
        const windowWidth = window.innerWidth

        if (
            this.options.responsive &&
            this.options.responsive.length &&
            this.options.responsive !== null
        ) {
            targetBreakpoint = null

            for (breakpoint in this.breakpoints) {
                if (this.breakpoints.hasOwnProperty(breakpoint)) {
                    if (windowWidth <= this.breakpoints[breakpoint]) {
                        targetBreakpoint = this.breakpoints[breakpoint]
                    }
                }
            }

            if (targetBreakpoint !== null) {
                this.activeBreakpoint = targetBreakpoint
                this.options = { ...this.options, ...this.breakpointSettings[targetBreakpoint] }
            } else {
                if (this.activeBreakpoint !== null) {
                    this.activeBreakpoint = null
                    targetBreakpoint = null

                    this.options = { ...this.options, ...this.originalSettings }
                }
            }
        }
    }

    /**
     * Rebuild the storage and update the canvas.
     *
     * @private
     */
    _refresh() {
        this._initializeStorage()
        this._draw()
    }

    /**
     * Kick off various things on window resize.
     *
     * @private
     */
    _resize() {
        // this.element.width = this.element.offsetParent
        //     ? this.element.offsetParent.clientWidth * this.ratio
        //     : this.element.clientWidth * this.ratio

        // if (this.element.offsetParent && this.element.offsetParent.nodeName === 'BODY') {
        //     this.element.height = window.innerHeight * this.ratio
        // } else {
        //     this.element.height = this.element.offsetParent
        //         ? this.element.offsetParent.clientHeight * this.ratio
        //         : this.element.clientHeight * this.ratio
        // }

        // this.context.scale(this.ratio, this.ratio)

        clearTimeout(this.windowDelay)

        this.windowDelay = window.setTimeout(() => {
            this._checkResponsive()
            this._refresh()
        }, 50)
    }

    /**
     * Animates the plugin particles by calling the draw method.
     *
     * @private
     */
    _animate(ts) {
        if (ts - this.lastTS >= frameTime) {
            this.lastTS = ts
            this._draw()
        }

        this._animation = window.requestAnimationFrame(this._anim)
    }

    /**
     * Restarts the particles animation by calling _animate.
     *
     * @public
     */
    resumeAnimation() {
        if (!this._animation) {
            this._animation = window.requestAnimationFrame(this._anim)
        }
    }

    /**
     * Pauses/stops the particle animation.
     *
     * @public
     */
    pauseAnimation() {
        if (!this._animation) return

        window.cancelAnimationFrame(this._animation)

        this._animation = null
    }

    /**
     * Draws the plugin particles.
     *
     * @private
     */
    _draw() {
        const element = this.element
        const parentWidth = element.offsetParent
            ? element.offsetParent.clientWidth
            : element.clientWidth
        let parentHeight = element.offsetParent
            ? element.offsetParent.clientHeight
            : element.clientHeight

        if (element.offsetParent && element.offsetParent.nodeName === 'BODY') {
            parentHeight = window.innerHeight
        }

        // this.context.clearRect(0, 0, element.width, element.height)
        // this.context.beginPath()

        for (const particle of this.storage) {
            // if (this.options.showParticles) {
            //     particle._draw()
            // }

            particle._updateCoordinates(parentWidth, parentHeight)
        }

        // if (this.options.connectParticles) {
        //     storage.sort(particleCompareFunc)
        //     this._updateEdges()
        // }
    }

    // _updateEdges() {
    //     const minDistance = this.options.minDistance
    //     const storage = this.storage
    //     const storageLength = storage.length

    //     for (let i = 0; i < storageLength; i++) {
    //         const p1 = storage[i]

    //         for (let j = i + 1; j < storageLength; j++) {
    //             const p2 = storage[j]
    //             let distance
    //             const r = p1.x - p2.x
    //             const dy = p1.y - p2.y

    //             distance = Math.sqrt(r * r + dy * dy)

    //             if (Math.abs(r) > minDistance) {
    //                 break
    //             }

    //             if (distance <= minDistance) {
    //                 this._drawEdge(p1, p2, 1.2 - distance / minDistance)
    //             }
    //         }
    //     }
    // }

    // _drawEdge({ x, y, color }, { x, y, color }, opacity) {
    //     const gradient = this.context.createLinearGradient(x, y, x, y)

    //     const color1 = _hex2rgb(color)
    //     const color2 = _hex2rgb(color)

    //     gradient.addColorStop(0, `rgba(${color1.r},${color1.g},${color1.b},${opacity})`)
    //     gradient.addColorStop(1, `rgba(${color2.r},${color2.g},${color2.b},${opacity})`)

    //     this.context.beginPath()
    //     this.context.strokeStyle = gradient
    //     this.context.moveTo(x, y)
    //     this.context.lineTo(x, y)
    //     this.context.stroke()
    //     this.context.fill()
    //     this.context.closePath()
    // }
}

class Particle {
    constructor(parent, options) {
        const speed = options.speed
        // const color =
        //     options.color instanceof Array
        //         ? options.color[Math.floor(Math.random() * options.color.length)]
        //         : options.color

        this.element = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        // this.element.setAttribute('fill', color)
        this.element.classList.add('particle')
        this.element.setAttribute('transform', 'rotate(45)')
        parent.appendChild(this.element)
        this.options = options

        const canvas = document.querySelector(options.selector)
        this.x = canvas.offsetParent
            ? Math.random() * canvas.offsetParent.clientWidth
            : Math.random() * canvas.clientWidth

        if (canvas.offsetParent && canvas.offsetParent.nodeName === 'BODY') {
            this.y = Math.random() * window.innerHeight
        } else {
            this.y = canvas.offsetParent
                ? Math.random() * canvas.offsetParent.clientHeight
                : Math.random() * canvas.clientHeight
        }

        this.vx = Math.random() * speed * 2 - speed
        this.vy = Math.random() * speed * 2 - speed
        this.radius = 0.5 + Math.random() * Math.random() // * options.sizeVariations
        // this.color = color

        this.element.setAttribute('width', this.radius * 2)
        this.element.setAttribute('height', this.radius * 2)

        this._draw()
    }

    _draw() {
        // this.context.save()
        // this.context.translate(this.x, this.y)
        // this.context.moveTo(0, 0)
        // this.context.beginPath()
        // this.context.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false)
        // this.context.fillStyle = this.color
        // this.context.fill()
        // this.context.restore()
    }

    _updateCoordinates(parentWidth, parentHeight) {
        let x = this.x + this.vx
        let y = this.y + this.vy
        const radius = this.radius

        if (x + radius > parentWidth) {
            x = radius
        } else if (x - radius < 0) {
            x = parentWidth - radius
        }

        if (y + radius > parentHeight) {
            y = radius
        } else if (y - radius < 0) {
            y = parentHeight - radius
        }

        this.x = x
        this.y = y

        this.element.setAttribute('x', x)
        this.element.setAttribute('y', y)
    }
}

// function particleCompareFunc({ x, y }, { x, y }) {
//     if (x < x) {
//         return -1
//     } else if (x > x) {
//         return 1
//     } else if (y < y) {
//         return -1
//     } else if (y > y) {
//         return 1
//     }

//     return 0
// }

// function _hex2rgb(hex) {
//     const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

//     return result
//         ? {
//               r: parseInt(result[1], 16),
//               g: parseInt(result[2], 16),
//               b: parseInt(result[3], 16),
//           }
//         : null
// }
